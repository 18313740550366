import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import NoSceneImg from '../assets/no-scene-3-cover-thumbnail.jpg';
import NoSceneIssue from '../assets/issues/No-Scene-3-Site.pdf';
import BreakfastMP3 from '../assets/breakfast.mp3';
import steve from "../assets/Steve-Urkel.png";

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />

        <div className="hero">
            <img src={NoSceneImg} alt="" height="800" style={{ margin: '35px auto', border: '12px solid blue', display: 'block' }}/>

            <div className="hero-content">
                <h1 className="title">ISSUE 3 OUT NOW</h1>

                <a className="yellow button" href={NoSceneIssue}>
                    DOWNLOAD ISSUE 3 NOW
                </a>
            </div>
        </div>

        <div className="content">

            <div className="buttons">
                <Link className="button" to="/media">PAST ISSUES</Link>
                <Link className="button" to="/guestbook">GUESTBOOK</Link>
            </div>

            <table style={{ border: '1px inset black', margin: '40px auto'}}>
                <tr style={{ border: '1px inset black'}}>
                    <td style={{ border: '1px inset black', color: 'black'}}>
                        FOR EMAIL INQUIRIES PLEASE EMAIL
                        <a href="mailto:CoolCatSassyBrat@no-scene.com">
                            CoolCatSassyBrat@no-scene.com
                        </a>
                    </td>
                </tr>
                <tr style={{ border: '1px inset black'}}>
                    <td style={{ border: '1px inset black', color: 'black'}}>
                        FOR PARTNERSHIPS AND ADVERTISING PLEASE EMAIL
                        <a href="mailto:MichaelScarn@no-scene.com">MichaelScarn@no-scene.com</a>
                    </td>
                </tr>
            </table>

            <audio src={BreakfastMP3} type="audio/mpeg" controls id="audio">
                Your browser does not support the audio element.
            </audio>

        </div>

        <div className="steve">
            <div className="inner-steve">
                <p>Did I do(wnload) that?</p>
                <img src={steve} alt="Steve Urkle"/>
            </div>
        </div>

    </Layout>
);

export default IndexPage;
